<template>
    <div>
        <van-radio-group v-model="config.value" direction="horizontal" class="radio_content">
            <van-radio v-for="(item, index) in config.list" class="radio_list" :key="index" :name="item.ladel">
                <template #icon="props">
                    <img class="img-icon"
                        :src="props.checked ? 'https://cdn.health.healthplatform.xyz/Fr5wRRVEApAOR6z4b-B7gaIkk0mf' : 'https://cdn.health.healthplatform.xyz/FvRD7ugPFrjXZPhXtssjKEioq0ER'" />
                </template>
                <span class="radio_box">{{ item.title }}</span>
            </van-radio>
        </van-radio-group>
    </div>
</template>
<script>
export default {
    props: {
        config: {
            type: Object,
        }
    },
    data() {
        return {
            value: '',

        }
    },
    methods: {

    }
}
</script>
<style scoped>
.img-icon {
    height: 28px;
}

.radio_box {
    font-family: PingFang SC, PingFang SC;
    font-weight: 500;
    font-size: 26px;
    color: #A15E00;
}
.radio_list{
    /* flex: 1; */
    margin-right: 50px;
}
.radio_content{
    display: flex;
    justify-content: center;
    text-align: center;
    margin: 34px 0 53px;
}
</style>
