<template>
    <div class="contaner_w">
        <new-header :config="headerConfig"></new-header>
        <div class="workpoints_info">
            <div class="workpoints_t">
                当前工分：<span>54564.00</span>
            </div>
            <div class="user_text">
                <div>用户须知：</div>
                <div>1.企业工分将会根据对应平台消费购物<span style="color:#FF3232">完成订单</span>自愿进行 积分，订单工分获取比例根据订单产出时间对应比例折算。 </div>
                <div>2.每个订单只可兑换一次，兑换后将不支持收购，兑换前 请确认个人信息资料是否正确；点击查看<span style="color:#0091FF">个人中心</span></div>
            </div>
            <div class="submit_w" @click="submitBtn">申请兑换</div>
            <div class="message_l">
                <div class="message_w">
                    <div style="display: flex;">
                        <img src="../../assets/Group_42229@2x.png" />
                        <div class="user_info">
                            <div>申请时间：2024-11-11</div>
                            <div>张**：135*****4941</div>
                            <div>工分：135456</div>
                        </div>
                    </div>
                    <div class="status_text">
                        <div>当前状态：进行中</div>
                        <div class="btn">查看详情</div>
                    </div>
                </div>

                <div class="message_w">
                    <div style="display: flex;">
                        <img src="../../assets/Group_42229@2x.png" />
                        <div class="user_info">
                            <div>申请时间：2024-11-11</div>
                            <div>张**：135*****4941</div>
                            <div>工分：135456</div>
                        </div>
                    </div>
                    <div class="status_text">
                        <div>当前状态：进行中</div>
                        <div class="btn">查看详情</div>
                    </div>
                </div>
            </div>
        </div>


        <!-- 提示弹窗 -->
        <van-dialog v-model="show" title="请选择兑换方式" :show-confirm-button="false" :closeOnClickOverlay="true">
            <div class="content_box_r">
                <customradio :config="radioConfig"></customradio>
                <div class="radio_text">
                    <div>用户须知：</div>
                    <div>1.增值方式即在购购平台“企业工会增值专区”存在购物订单时，当订单已完成后直接通过订单自动匹配当月政策置换工分，以订单产生时间为准；</div>
                    <div>2.订单置换工分后将不支持售后服务，如有纠纷请联系客服</div>
                </div>
                <div class="next_ntn">下一步</div>
            </div>
        </van-dialog>
    </div>
</template>
<script type>
import newHeader from "@/components/newHeader";
import customradio from "../../components/custom/customradio";
export default {
    data() {
        return {
            headerConfig: {
                show: true,
                title: '企业积分',
            },
            show: false,
            radioConfig: {
                value: '',
                list: [
                    { checked: true, title: '增值兑换', ladel: 1 },
                    { checked: false, title: '分享补金兑换', ladel: 2 },
                ]
            }
        };
    },
    components: {
        newHeader,
        customradio
    },
    created() {
        document.title = "";

    },
    methods: {
        submitBtn() {
            this.show = true
        }
    }
};
</script>
<style>
.contaner_w {
    background-color: #F9F9FB;
}

.workpoints_t {
    font-family: PingFang SC, PingFang SC;
    font-weight: bold;
    font-size: 34px;
    color: #1A1A1A;
    margin: 37px 0;
}

.workpoints_t span {
    color: #0169FF;
    font-size: 34px;
}

.workpoints_info {
    padding: 0 30px;
    box-sizing: border-box;
}

.user_text {
    font-family: PingFang SC, PingFang SC;
    font-weight: bold;
    font-size: 26px;
    color: rgba(26, 26, 26, 0.8);
    line-height: 50px;
}

.submit_w {
    margin: 37px 0 30px;
    width: 200px;
    height: 66px;
    background: linear-gradient(180deg, #252324 0%, #3C3633 100%), #2F2B2B;
    border-radius: 10px 10px 10px 10px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 500;
    font-size: 28px;
    color: #FFFFFF;
    text-align: center;
    line-height: 66px
}

.message_w {
    width: 690px;
    height: 200px;
    background: linear-gradient(180deg, #EABC7F 0%, #FFFFFF 96%);
    box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.05);
    border-radius: 20px 20px 20px 20px;
    padding: 23px 28px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.message_w img {
    width: 80px;
    height: 80px;
    margin: 37px 33px 0 0;
}

.user_info {
    font-family: PingFang SC, PingFang SC;
    font-weight: 500;
    font-size: 24px;
    color: #1A1A1A;
    margin-top: 19px;
}

.user_info div {
    margin-bottom: 10px;
}

.status_text {
    font-family: PingFang SC, PingFang SC;
    font-weight: 500;
    font-size: 24px;
    color: #1A1A1A;
}

.status_text .btn {
    width: 180px;
    height: 66px;
    background: #B4905D;
    border-radius: 33px 33px 33px 33px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 500;
    font-size: 26px;
    color: #FFFFFF;
    text-align: center;
    line-height: 66px;
    margin-top: 35px;
}

.radio_text {
    font-family: PingFang SC, PingFang SC;
    font-weight: bold;
    font-size: 26px;
    line-height: 40px;
    color: rgba(26, 26, 26, 0.8);
    padding: 0 44px;
    box-sizing: border-box;
}

.next_ntn {
    width: 180px;
    height: 66px;
    background: linear-gradient(180deg, #252324 0%, #3C3633 100%), #0091FF;
    border-radius: 12px 12px 12px 12px;
    text-align: center;
    line-height: 66px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 500;
    font-size: 26px;
    color: #FFFFFF;
    margin: 37px auto 0;
}
.content_box_r{
    padding-bottom: 44px;
    box-sizing: border-box;
}
</style>
